
import { defineComponent } from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import BaseOverlay from "../components/BaseOverlay.vue";
import { determineCurrentStep } from "../router/index";

const computed = {
  // ...mapGetters([
  //   "content",
  //   "subContent",
  //   "getOverlayReferrer",
  //   "getOverlayContent",
  // ]),
};
const components = {
  BaseOverlay,
};

const watch = {
  overlayContent: {
    deep: true,
    handler() {
      document
        .querySelectorAll("[data-variation-target]")
        .forEach((variationButton) => {
          variationButton.addEventListener("click", function (e) {
            e.preventDefault();
            document
              .querySelectorAll("[data-variation]")
              .forEach((variationWrapper) => {
                variationWrapper.classList.add("invisible");
                let variationButtonValue = variationButton.getAttribute(
                  "data-variation-target"
                );
                let variationWrapperValue = variationWrapper.getAttribute(
                  "data-variation-target"
                );
                if (variationButtonValue == variationWrapperValue) {
                  variationWrapper.classList.remove("invisible");
                }
              });
          });
        });
    },
  },
};

export default defineComponent({
  name: "StoreVoucherDashboard",
  data() {
    return {
      isLinkClicked: false,
      isCouponClicked: false,
      currentEasterEgg: null,
      couponReceived: false,
      animateTotal: false,
      overlayContent: ``,
      cardOverlayContent: `
        <div class="cardOverlay">
          <div data-variation="0">
            <div class="cardOverlayHeading">
                <h4 class="mb-30">+ easteregg.template +</h4>
            </div>
            <ul class="mb-60">
                <li><span class="bold">Nur heute:</span> 10€ zusätzlicher Sofort-Einkaufsgutschein</li>
                <li>10€ Welcome-Gutschein für einen weiteren Einkauf im Store oder Online Shop</li>
                <li>Fashion Points sammeln, einlösen und sparen!</li>
                <li>Erhalte eine Geburtstagsüberraschung</li>
                <li>Exklusiver Zugang zu Rabatten, Aktionen und Events</li>
            </ul>
            <button class="nextButton" data-variation-target="1">Jetzt anmelden</button>
            <button data-variation-target="2" class="nextButton mb-30">Bereits Card-Kunde</button>
            <a class="backButton">Zurück</a>
          </div>

          <div data-variation="1">
            <div class="cardOverlayHeading">
                <h4 class="mb-30">+ easteregg.templateVariationA +</h4>
            </div>
            <p>1. Jetzt online für die s.Oliver Card anmelden:</p>
            <a href="/" class="nextButton">Jetzt anmelden</a>
            <p>2.Nach der Registrierung erhältst du eine Mail mit deiner persönlichen s.Oliver Card-Nummer.</p>
            <p>3.Gib die ersten 4 Ziffern deiner s.Oliver Card-Nummer ein:</p>
            <input type="text">
            <a href="/" class="nextButton">Absenden und 10€ abholen</a>
            <a href="/" class="backButton">Zurück</a>
          </div>
        </div>
      `,
      questionOverlayContent: `
        <p class="rewardText mt-120 mb-30">
          Warum bist du heute im s.Oliver Store?
        </p>
        <div class="nextButtonWrapper">
          <button class="nextButton ">QR Code / Gewinnspiel</button>
          <button class="nextButton ">Kunden-Newsletter</button>
          <button class="nextButton">Artikel im Schaufenster</button>
          <button class="nextButton">Etwas anderes</button>
        </div>
        <div class="backButtonWrapper">
          <p class="backButton">Ich möchte das nicht beantworten</p>
        </div>
      `,
      receivedOverlayContent: `
        <div class="mb-60">
            <svg width="135" height="98" viewBox="0 0 135 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="checkmark" d="M2 52.1333L45.6667 96L133 2" stroke="url(#paint0_linear_573_1114)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <defs>
                    <linearGradient id="paint0_linear_573_1114" x1="67.5" y1="2" x2="67.5" y2="96" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00AD64" />
                        <stop offset="1" stop-color="#00AD64" stop-opacity="0.7" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
        <p class="totalvalue mb-30">
            +{{ this.currentEasterEgg.coupon.value }}€
        </p>
        <p>Zusätzlicher Einkaufsgutschein</p>
      `,
    };
  },
  components: {
    BaseOverlay,
  },
  computed: {
    ...mapState(["currentStore", "currentProgress", "tracking"]),
    totalDiscount(): number {
      if (null === this.currentProgress.initialDiscount) return 0;
      let discount = this.currentProgress.initialDiscount;
      this.currentProgress.addedDiscounts.forEach((easterEggId: any) => {
        const matchingEasterEgg =
          this.currentStore.activeEvent.actionItems.find((easterEgg: any) => {
            return easterEgg.id === easterEggId;
          });
        discount += matchingEasterEgg.coupon.value;
      });
      return discount;
    },
  },
  methods: {
    ...mapActions([
      "setCurrentProgress",
      "setPreviousStep",
      "setCurrentStep",
      "stashTrackingInfo",
      "setAppNavigated",
      // "setContent",
      // "setSubContent",
      // "setLightboxContent",
      // "destroyLightbox",
    ]),
    toggleCouponClass() {
      this.isCouponClicked = true;
    },
    nextStep() {
      // falls Link geklickt
      if (this.isLinkClicked) {
        let extendedEasterEgg = this.currentStore.activeEvent.actionItems.find(
          (ee: any) => {
            return ee.templateType === "extended";
          }
        );
        if (typeof extendedEasterEgg !== "undefined") {
          //prüfen ob bereits Tracking für sOlivercard vorhanden
          let extendedTrackingInfo = this.tracking.data.easterEggData.find(
            (ee: any) => {
              return ee.templateType === "extended";
            }
          );
          if (typeof extendedTrackingInfo === "undefined") {
            let newInfo = {
              path: "easteregg_" + extendedEasterEgg.templateType + "_abort",
              easterEggData: [...this.tracking.data.easterEggData],
            };
            newInfo.easterEggData.push({
              id: extendedEasterEgg.id,
              templateType: extendedEasterEgg.templateType,
              payload: {
                linkClicked: true,
              },
            });
            this.stashTrackingInfo(newInfo);
          }
        }
      }
      //add tracking info
      this.setAppNavigated(true);
      this.setPreviousStep();
      this.setCurrentStep("shopping");
      let newInfo = {
        finalDiscount: this.totalDiscount,
        shoppingTimestamp: Math.floor(new Date().getTime() / 1000),
      };
      this.stashTrackingInfo(newInfo);

      const storeName = this.$router.currentRoute.value.params.storeName;
      this.$router.push(`/${storeName}/shopping`).then(() => {
        this.setAppNavigated(false);
      });
    },
    callCoupon(easteregg: any) {
      this.isLinkClicked = false;
      this.currentEasterEgg = easteregg;
      this.buildOverlay(easteregg);
      this.isCouponClicked = true;
      this.couponReceived = false;

      const newInfo = {
        path: "easteregg_" + easteregg.templateType + "_begin",
      };
      this.stashTrackingInfo(newInfo);
      document.body.scrollTo({ top: 1 });
      window.scrollTo({ top: 1 });
    },
    buildOverlay(easteregg: any) {
      this.overlayContent = ``;
      if (easteregg.templateType == "extended") {
        this.cardOverlayContent = `
            <div class="cardOverlay">
        `;
        this.cardOverlayContent +=
          `<div data-variation="1">` +
          easteregg.template +
          `
                <button class="nextButton" data-variation-target="2">Jetzt anmelden</button>
                <button data-variation-target="3" class="nextButton mb-30">Bereits Card-Kunde</button>
                <p class="backButton backButtonClose">Zurück</p>
                </div>
        `;
        this.cardOverlayContent +=
          `<div data-variation="2" class="invisible">` +
          easteregg.templateVariationA +
          `<input type="number" placeholder="Erste 4 Ziffern eingeben" class="receivedCheck" />
           <button class="nextButton openReceivedCheck">Absenden und ` +
          easteregg.coupon.value +
          `€ abholen</button>
           <p class="backButton" data-variation-target="1">Zurück</p>
           </div>
        `;
        this.cardOverlayContent +=
          `<div data-variation="3" class="invisible">` +
          easteregg.templateVariationB +
          `<input type="number" placeholder="Erste 4 Ziffern eingeben" class="receivedCheck" />
            <button class="nextButton openReceivedCheck">Absenden und ` +
          easteregg.coupon.value +
          `€ abholen</button>
          <p class="backButton" data-variation-target="1">Zurück</p>
          </div>
        `;
        this.cardOverlayContent += `</div>`;
        this.overlayContent = this.cardOverlayContent;
        this.$nextTick(() => {
          this.$nextTick(() => {
            document
              .querySelectorAll("[data-variation-target]")
              .forEach((variationButton) => {
                variationButton.addEventListener("click", function (e) {
                  e.preventDefault();
                  document
                    .querySelectorAll("[data-variation]")
                    .forEach((variationWrapper) => {
                      variationWrapper.classList.add("invisible");
                      let variationButtonValue = variationButton.getAttribute(
                        "data-variation-target"
                      );
                      let variationWrapperValue =
                        variationWrapper.getAttribute("data-variation");
                      if (variationButtonValue == variationWrapperValue) {
                        variationWrapper.classList.remove("invisible");
                      }
                    });
                  document.body.scrollTo({ top: 1 });
                  window.scrollTo({ top: 1 });
                });
              });
            document
              .querySelectorAll(".openReceivedCheck")
              .forEach((receiveButton) => {
                receiveButton.addEventListener("click", () => {
                  this.receiveCouponCheck(easteregg, receiveButton);
                });
              });
            document
              .querySelectorAll(".backButtonClose")
              .forEach((receiveButton) => {
                receiveButton.addEventListener("click", () => {
                  this.closeLightbox();
                });
              });
            if (document.querySelector(".cardOverlay a")) {
              document
                .querySelectorAll(".cardOverlay a")
                .forEach((cardOverlayLink) => {
                  cardOverlayLink.addEventListener("click", (e) => {
                    e.preventDefault();
                    const { target } = e as unknown as {
                      target: HTMLAnchorElement;
                    };
                    if (target) {
                      (target as HTMLAnchorElement).classList.add("clicked");
                      this.isLinkClicked = true;
                      window.open((target as HTMLAnchorElement).href, "_blank");
                    }
                  });
                });
            }
          });
        });
      } else if (easteregg.templateType == "questions") {
        this.overlayContent =
          `
        <div class="cardOverlay">` +
          easteregg.description +
          `<div class="nextButtonWrapper">`;
        easteregg.answers.forEach((answer: string) => {
          this.overlayContent +=
            `<button data-answer="` +
            answer +
            `" class="nextButton openReceived">` +
            answer +
            `</button>`;
        });
        this.overlayContent += `</div>
        <div class="backButtonWrapper">
          <p class="backButton backButtonClose">Ich möchte das nicht beantworten</p>
        </div>`;
        this.$nextTick(() => {
          this.$nextTick(() => {
            document
              .querySelectorAll(".openReceived")
              .forEach((receiveButton) => {
                let button = receiveButton as HTMLElement;
                receiveButton.addEventListener("click", () => {
                  this.couponReceived = true;
                  let payload = { answer: button.dataset.answer };
                  this.receiveCoupon(easteregg, payload);
                });
              });
            document
              .querySelectorAll(".backButtonClose")
              .forEach((receiveButton) => {
                receiveButton.addEventListener("click", () => {
                  this.closeLightbox();
                });
              });
          });
        });
      }
    },
    receiveCouponCheck(easteregg: any, button: Element) {
      document.querySelectorAll(".receivedCheck").forEach((receivedCheck) => {
        if (receivedCheck instanceof HTMLInputElement) {
          receivedCheck.classList.remove("error");
          let currentCode = receivedCheck.value;
          if (currentCode == easteregg.answer) {
            let currentPage = button.closest("[data-variation]");
            let newCustomer = true;
            if (currentPage !== null && currentPage instanceof HTMLElement) {
              newCustomer = currentPage.dataset.variation === "2";
            }
            let payload = {
              newCustomer,
              linkClicked: this.isLinkClicked,
            };
            this.couponReceived = true;
            this.receiveCoupon(easteregg, payload);
          } else {
            setTimeout(() => {
              receivedCheck.classList.add("error");
            }, 1);
          }
        }
      });
    },
    receiveCoupon(easteregg: any, payload: any) {
      this.couponReceived = true;
      if (!this.currentProgress.addedDiscounts.includes(easteregg.id)) {
        //update current progress
        let updatedProgress = {
          ...this.currentProgress,
          addedDiscounts: [
            ...this.currentProgress.addedDiscounts,
            easteregg.id,
          ],
        };
        this.setCurrentProgress(updatedProgress);
        //update current tracking information
        let newInfo = {
          path: "easteregg_" + easteregg.templateType + "_complete",
          easterEggData: [...this.tracking.data.easterEggData],
        };
        newInfo.easterEggData.push({
          id: easteregg.id,
          templateType: easteregg.templateType,
          payload,
        });
        this.stashTrackingInfo(newInfo);
      }
      this.overlayContent =
        `
                <div class="mb-60">
            <svg width="135" height="98" viewBox="0 0 135 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="checkmark" d="M2 52.1333L45.6667 96L133 2" stroke="url(#paint0_linear_573_1114)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <defs>
                    <linearGradient id="paint0_linear_573_1114" x1="67.5" y1="2" x2="67.5" y2="96" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00AD64" />
                        <stop offset="1" stop-color="#00AD64" stop-opacity="0.7" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
        <p class="totalvalue mb-30">` +
        easteregg.coupon.value +
        `€` +
        `</p>
        <p>Zusätzlicher Einkaufsgutschein</p>
        `;
      setTimeout(() => {
        this.closeLightbox();
      }, 1500);
    },
    // getCoupon(eastereggId: number) {
    //   if (!this.currentProgress.addedDiscounts.includes(eastereggId)) {
    //     let updatedProgress = {
    //       ...this.currentProgress,
    //       addedDiscounts: [...this.currentProgress.addedDiscounts, eastereggId],
    //     };
    //     this.setCurrentProgress(updatedProgress);
    //   }
    //   this.couponReceived = true;
    //   setTimeout(() => {
    //     console.log("joho");
    //     this.closeLightbox();
    //   }, 1000);
    //   // this.closeLightbox();
    // },
    isIncomplete(eastereggId: number) {
      return !this.currentProgress.addedDiscounts.includes(eastereggId);
    },
    closeLightbox() {
      this.isCouponClicked = false;
      this.currentEasterEgg = null;
      if (this.couponReceived == true) {
        this.animateTotal = false;
        setTimeout(() => {
          this.animateTotal = true;
        }, 1);
      }
      document.body.scrollTo({ top: 1 });
      window.scrollTo({ top: 1 });
    },
    // callOverlay() {
    //   this.setLightboxContent({
    //     pagecontent: `<div class="frame-container-1200"><div class="text-center"></div><p>test</p></div>`,
    //     referrer: "content",
    //   });
    // },
  },
  mounted() {
    determineCurrentStep();
  },
});
