import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { checkCurrentPathAgainstStores, router } from "./router";
import store from "./store";
import validateStorage from "./store/helper/management";
import "./scss/base.scss";

createApp(App).use(store).use(router).mount("#app");

if (window.location.pathname.slice(1) !== "") {
  validateStorage().then(() => {
    checkCurrentPathAgainstStores();
  });
}
