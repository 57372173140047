
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import * as JsBarcode from "jsbarcode";
import InlineSvg from "vue-inline-svg";
import { determineCurrentStep } from "../router/index";

export default defineComponent({
  name: "StoreVoucherCodePreview",
  data() {
    return {
      endTime: 0,
      timerText: "05:00",
      timer: 0,
    };
  },
  components: {
    InlineSvg,
  },
  computed: {
    ...mapState(["currentStore", "currentProgress"]),
    totalDiscount(): number {
      if (null === this.currentProgress.initialDiscount) return 0;
      let discount = this.currentProgress.initialDiscount;
      this.currentProgress.addedDiscounts.forEach((easterEggId: any) => {
        const matchingEasterEgg =
          this.currentStore.activeEvent.actionItems.find((easterEgg: any) => {
            return easterEgg.id === easterEggId;
          });
        discount += matchingEasterEgg.coupon.value;
      });
      return discount;
    },
  },
  methods: {
    ...mapActions([
      "setCurrentProgress",
      "setPreviousStep",
      "setCurrentStep",
      "stashTrackingInfo",
      "setAppNavigated",
    ]),
    nextStep() {
      this.setAppNavigated(true);
      this.setPreviousStep();
      this.setCurrentStep("finish");
      this.stashTrackingInfo({});
      const storeName = this.$router.currentRoute.value.params.storeName;
      this.$router.push(`/${storeName}/finish`).then(() => {
        this.setAppNavigated(false);
      });
    },
    startCountdown() {
      this.endTime = new Date(
        this.currentProgress.stepData.expiryTime * 1000
      ).getTime();

      this.timer = setInterval(() => {
        const now = new Date().getTime();
        const timeDifference = this.endTime - now;

        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        this.timerText = `${String(minutes).padStart(2, "0")}:${String(
          seconds
        ).padStart(2, "0")}`;

        if (timeDifference < 0) {
          clearInterval(this.timer);
          this.timerText = "00:00";
          this.nextStep();
        }
      }, 1000);
    },
  },
  mounted() {
    this.endTime = new Date(
      this.currentProgress.stepData.expiryTime * 1000
    ).getTime();
    const now = new Date().getTime();
    const timeDifference = this.endTime - now;

    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    this.timerText = `${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;
    this.startCountdown();
    let jsbarcode = JsBarcode.default;
    let fittingBarcode = this.currentStore.barcodes.find(
      (barcode: any) => barcode.value === this.totalDiscount
    );
    if (null === fittingBarcode) fittingBarcode = "Something went wrong.";
    else fittingBarcode = fittingBarcode.code;
    // let fittingBarcode = "EURTM2600150110020";
    jsbarcode("#barcode", fittingBarcode, {
      font: "roboto",
    });
    determineCurrentStep();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
});
