
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";

export default defineComponent({
  name: "StoreMainVoucherFollowup",
  computed: {
    ...mapState(["currentStore", "currentProgress"]),
  },
  methods: {
    ...mapActions(["setCurrentProgress"]),
    prevStep() {
      console.log("!");
    },
    nextStep() {
      console.log("?");
    },
  },
});
