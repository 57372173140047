// Introduce interval that clears the tracking queue
// Clear the interval when connection is lost and reinit when connection is back

import store from "../index";
import axios from "axios";
import router from "../../router";

export const submitTrackingData = async () => {
  if (store.state.tracking.queue.length > 0) {
    if (store.state.tracking.data.id > 0) {
      // PATCH tracking info
      await store
        .dispatch("fetchApiToken")
        .then(async function () {
          const jwtToken = store.state.apiToken.token;

          const queueCopy = [...store.state.tracking.queue];

          for (const entry of queueCopy) {
            // console.log(entry);
            try {
              await axios
                .patch(
                  process.env.VUE_APP_BACKEND_URL +
                    `/api/tracking_infos/${store.state.tracking.data.id}`,
                  entry,
                  {
                    headers: {
                      accept: "application/json",
                      "Content-Type": "application/merge-patch+json",
                      Authorization: `Bearer ${jwtToken}`,
                    },
                  }
                )
                .then(function (response) {
                  store.dispatch("popTrackingInfo");
                  store.dispatch("updateTrackingData", response.data);
                });
            } catch (error) {
              console.error("Fehler bei der Übermittlung:", error);
              break;
            }
          }
        })
        .catch(function (error) {
          console.log("error during login");
          console.log(error);
          store.dispatch("setErrorCode", "ERROR_API");
          router.push("/");
        });
    } else {
      // POST tracking info
      await store
        .dispatch("fetchApiToken")
        .then(async function () {
          const jwtToken = store.state.apiToken.token;
          const entry = store.state.tracking.queue[0];
          try {
            await axios
              .post(
                process.env.VUE_APP_BACKEND_URL + `/api/tracking_infos`,
                entry,
                {
                  headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                  },
                }
              )
              .then(function (response) {
                store.dispatch("popTrackingInfo");
                store.dispatch("updateTrackingData", response.data);
              });
          } catch (error) {
            console.error("Fehler bei der Übermittlung:", error);
          }
        })
        .catch(function (error) {
          console.log("error during login");
          console.log(error);
          store.dispatch("setErrorCode", "ERROR_API");
          router.push("/");
        });
    }
  }
};

window.addEventListener("online", () => {
  // console.log("Und wir sind wieder online");
  store.dispatch("setOfflineMode", false);
  submitTrackingData();
});

window.addEventListener("offline", () => {
  // console.log("Und wir sind offline");
  store.dispatch("setOfflineMode", true);
});

export default submitTrackingData;
