
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import { determineCurrentStep } from "../router/index";

export default defineComponent({
  name: "StoreVoucherShopping",
  computed: {
    ...mapState(["currentStore", "currentProgress"]),
    totalDiscount(): number {
      if (null === this.currentProgress.initialDiscount) return 0;
      let discount = this.currentProgress.initialDiscount;
      this.currentProgress.addedDiscounts.forEach((easterEggId: any) => {
        const matchingEasterEgg =
          this.currentStore.activeEvent.actionItems.find((easterEgg: any) => {
            return easterEgg.id === easterEggId;
          });
        discount += matchingEasterEgg.coupon.value;
      });
      return discount;
    },
  },
  methods: {
    ...mapActions([
      "setCurrentProgress",
      "setPreviousStep",
      "setCurrentStep",
      "stashTrackingInfo",
      "setAppNavigated",
    ]),
    prevStep() {
      this.setAppNavigated(true);
      this.setPreviousStep();
      this.setCurrentStep("dashboard");
      this.stashTrackingInfo({});
      const storeName = this.$router.currentRoute.value.params.storeName;
      this.$router.push(`/${storeName}/dashboard`).then(() => {
        this.setAppNavigated(false);
      });
    },
    nextStep() {
      this.setAppNavigated(true);
      this.setPreviousStep();
      this.setCurrentStep("activation");
      this.stashTrackingInfo({});
      const storeName = this.$router.currentRoute.value.params.storeName;
      this.$router.push(`/${storeName}/activation`).then(() => {
        this.setAppNavigated(false);
      });
    },
  },
  mounted() {
    determineCurrentStep();
  },
});
