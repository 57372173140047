<template>
  <button
    style="position: fixed; top: 10px; right: 10px"
    @click="resetProgress"
    v-if="devMode"
  >
    💀
  </button>
  <div class="main-wrapper">
    <header class="mb-30">
      <div class="header-img-wrapper">
        <img alt="Vue logo" src="./assets/logo.svg" />
      </div>
      <span>Scan, play &amp; win</span>
    </header>
    <router-view v-slot="{ Component }" :key="$route.path">
      <transition name="slide" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
    <footer
      v-if="
        this.currentStore !== null && this.currentStore.imprintAndPrivacy != ''
      "
    >
      <a class="linktext imprint" @click="openImprint">
        Impressum und Datenschutz
      </a>
    </footer>
    <BaseOverlay
      v-if="showImprint"
      :page="currentProgress.step"
      @closeLightbox="closeImprint"
    >
      <template #mainContent>
        <div class="overlayWrapper">
          <div class="mt-60 inner-320">
            <div
              class="tos-wrapper"
              v-html="currentStore.imprintAndPrivacy"
            ></div>
            <div>
              <button class="mt-30 nextButton" @click="closeImprint">
                Schließen
              </button>
            </div>
          </div>
        </div>
      </template>
    </BaseOverlay>
  </div>
</template>

<style lang="scss">
body,
html {
  margin: 0;
}
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .main-wrapper {
    display: flex;
    flex-direction: column;
    // height: 100vh;
  }
}
body,
html,
#app,
#app .main-wrapper {
  height: 100%;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import { mapState, mapActions } from "vuex";
import BaseOverlay from "./components/BaseOverlay.vue";

export default {
  name: "App",
  data() {
    return {
      showImprint: false,
    };
  },
  components: {
    BaseOverlay,
  },
  methods: {
    ...mapActions([
      "setCurrentProgress",
      "setPreviousStep",
      "setCurrentStep",
      "destroyStorage",
    ]),
    resetProgress() {
      this.destroyStorage();
      this.$router.go(window.location.pathname.split("/")[1]);
      window.location.pathname = window.location.pathname.split("/")[1];
    },
    openImprint() {
      this.showImprint = true;
      document.body.scrollTo({ top: 1 });
      window.scrollTo({ top: 1 });
    },
    closeImprint() {
      this.showImprint = false;
      document.body.scrollTo({ top: 1 });
      window.scrollTo({ top: 1 });
    },
  },
  computed: {
    ...mapState(["currentStore", "currentProgress"]),
    devMode() {
      return process.env.NODE_ENV === "development";
    },
  },
};
</script>
