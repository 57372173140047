import { createStore } from "vuex";
import axios from "axios";
import submitTrackingData from "@/store/modules/tracking";
// import createPersistedState from "vuex-persistedstate";

//import BaseOverlay from "@/store/modules/BaseOverlay";

interface State {
  offlineMode: boolean;
  appNavigated: boolean;
  expires: number;
  apiToken: null | any;
  errorCode: string;
  currentProgress: {
    step: null | string;
    previousStep: null | string;
    stepData: null | any; // Ändern Sie den Typ hier je nach Bedarf
    initialDiscount: number;
    addedDiscounts: number[];
  };
  currentStore: null | string;
  expiryDate: null | Date;
  tracking: {
    data: {
      id: number;
      sessionId: string;
      initialTimestamp: number;
      initialDiscount: number;
      store: string;
      easterEggData: any[]; // Ändern Sie den Typ hier je nach Bedarf
      finalDiscount: number;
      finalTimestamp: number;
    };
    queue: any[]; // Ändern Sie den Typ hier je nach Bedarf
  };
}

const syncIdentifiers = ["currentStore", "currentProgress", "tracking"];

export const initialStoreState = {
  offlineMode: false,
  appNavigated: false,
  expires: 0,
  apiToken: null,
  errorCode: "ERROR_URL",
  currentProgress: {
    step: "landing", // current step
    previousStep: null, // previous step
    stepData: null, // additional info needed for current step
    initialDiscount: 0, // randomly assigned
    addedDiscounts: [], // array of completed easter egg IDs
  },
  currentStore: null,
  expiryDate: null,
  tracking: {
    data: {
      id: 0,
      sessionId: "",
      initialTimestamp: 0,
      initialDiscount: 0,
      store: "",
      easterEggData: [],
      finalDiscount: 0,
      finalTimestamp: 0,
    },
    queue: [],
  },
};

const store = createStore<State>({
  state: { ...initialStoreState },
  getters: {
    currentStore: function (state) {
      return state.currentStore;
    },
    currentProgress: function (state) {
      return state.currentProgress;
    },
    errorCode: function (state) {
      return state.errorCode;
    },
    tracking: function (state) {
      return state.tracking;
    },
    appNavigated: function (state) {
      return state.appNavigated;
    },
    offlineMode: function (state) {
      return state.offlineMode;
    },
    storageExpiry: function (state) {
      return state.expires;
    },
  },
  mutations: {
    UPDATE_CURRENTSTORE(state, payload) {
      state.currentStore = payload;
    },
    UPDATE_CURRENTPROGRESS(state, payload) {
      state.currentProgress = payload;
    },
    UPDATE_ERRORCODE(state, payload) {
      state.errorCode = payload;
    },
    UPDATE_TRACKING(state, payload) {
      state.tracking = payload;
    },
    UPDATE_TOKEN(state, payload) {
      state.apiToken = payload;
    },
    UPDATE_NAVIGATION(state, payload) {
      state.appNavigated = payload;
    },
    UPDATE_EXPIRY(state, payload) {
      state.expires = payload;
    },
    SET_OFFLINE(state, payload) {
      state.offlineMode = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, initialStoreState);
    },
  },
  actions: {
    setCurrentStore(context, payload) {
      context.commit("UPDATE_CURRENTSTORE", payload);
      localStorage.setItem("currentStore", JSON.stringify(payload));
    },
    setCurrentProgress(context, payload) {
      context.commit("UPDATE_CURRENTPROGRESS", payload);
      localStorage.setItem("currentProgress", JSON.stringify(payload));
    },
    setErrorCode(context, payload) {
      context.commit("UPDATE_ERRORCODE", payload);
    },
    setPreviousStep(context, payload = "") {
      if (payload === "") payload = this.state.currentProgress.step;
      const updatedProgress = {
        ...this.state.currentProgress,
        previousStep: payload,
      };
      context.dispatch("setCurrentProgress", updatedProgress);
    },
    setCurrentStep(context, payload) {
      const updatedProgress = {
        ...this.state.currentProgress,
        step: payload,
      };
      context.dispatch("setCurrentProgress", updatedProgress);
    },
    setTracking(context, payload) {
      context.commit("UPDATE_TRACKING", payload);
      localStorage.setItem("tracking", JSON.stringify(payload));
    },
    updateTrackingData(context, payload) {
      const trackingData = {
        ...this.state.tracking,
      };
      trackingData.data = payload;
      context.dispatch("setTracking", trackingData);
    },
    async stashTrackingInfo(context, payload) {
      if (payload.path)
        payload = {
          ...payload,
        };
      else
        payload = {
          ...payload,
          path: this.state.currentProgress.step ?? "init",
        };
      const trackingData = {
        ...this.state.tracking,
      };
      trackingData.queue.push(payload);
      context.dispatch("setTracking", trackingData);
      if (this.state.offlineMode === false) await submitTrackingData();
    },
    popTrackingInfo(context) {
      const trackingData = {
        ...this.state.tracking,
      };
      trackingData.queue.splice(0, 1);
      context.dispatch("setTracking", trackingData);
    },
    setApiToken(context, payload) {
      context.commit("UPDATE_TOKEN", payload);
    },
    async fetchApiToken(context) {
      if (
        null !== this.state.apiToken &&
        this.state.apiToken.expires > Math.floor(Date.now() / 1000)
      )
        return;
      await axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/api/login",
          {
            username: "api",
            password: "api",
          },
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then(async function (response) {
          if (response.data) {
            const token = {
              token: response.data.token,
              expires: Math.floor(Date.now() / 1000) + 3600,
            };
            context.dispatch("setApiToken", token);
          }
        });
    },
    setAppNavigated(context, payload) {
      context.commit("UPDATE_NAVIGATION", payload);
    },
    setOfflineMode(context, payload) {
      context.commit("SET_OFFLINE", payload);
    },
    setExpiry(context) {
      const currentDate = new Date();
      currentDate.setHours(23);
      currentDate.setMinutes(59);
      currentDate.setSeconds(59);
      const finalTimestamp = Math.floor(currentDate.getTime() / 1000);
      context.commit("UPDATE_EXPIRY", finalTimestamp);
      localStorage.setItem("expires", String(finalTimestamp));
    },
    destroyStorage(context) {
      context.commit("RESET_STATE");
      localStorage.clear();
    },
  },
  modules: {},
});

//load current progress and store data from localStorage if available
syncIdentifiers.forEach((syncIdentifier) => {
  const localItem = localStorage.getItem(syncIdentifier);
  if (localItem) {
    const commitIdentifier =
      "set" + syncIdentifier.charAt(0).toUpperCase() + syncIdentifier.slice(1);
    store.dispatch(commitIdentifier, JSON.parse(localItem));
  }
});

export default store;
