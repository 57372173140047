
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import InlineSvg from "vue-inline-svg";
import BaseOverlay from "../components/BaseOverlay.vue";
import { determineCurrentStep } from "../router/index";

export default defineComponent({
  name: "StoreLanding",
  data() {
    return {
      showAnimation: false,
      isChecked: false,
      showError: false,
      timer: 0,
      showTos: false,
    };
  },
  components: {
    InlineSvg,
    BaseOverlay,
  },
  mounted() {
    determineCurrentStep();
  },
  computed: {
    ...mapState(["currentStore", "currentProgress", "tracking"]),
    possibleMaxReward() {
      let reward = 0;
      if (this?.currentStore?.activeEvent?.maxReward)
        reward = this.currentStore.activeEvent.maxReward;
      return reward;
    },
    currentStoreLoaded(): boolean {
      return null !== this.currentStore;
    },
  },
  methods: {
    ...mapActions([
      "setCurrentProgress",
      "setPreviousStep",
      "setCurrentStep",
      "stashTrackingInfo",
      "setAppNavigated",
    ]),
    openTos() {
      this.showTos = true;
      document.body.scrollTo({ top: 1 });
      window.scrollTo({ top: 1 });
    },
    closeTos() {
      this.showTos = false;
      document.body.scrollTo({ top: 1 });
      window.scrollTo({ top: 1 });
    },
    handleButtonClick() {
      this.showError = false;
      if (!this.isChecked) {
        setTimeout(() => {
          this.showError = true;
        }, 1);
        return;
      }
      this.showError = false;
      this.calcInitialDiscount();
      this.nextStep();
    },
    nextStep() {
      this.setAppNavigated(true);
      // jo

      this.setPreviousStep();
      this.setCurrentStep("main");

      let newInfo = {
        initialDiscount: this.currentProgress.initialDiscount,
      };
      this.stashTrackingInfo(newInfo);

      this.showAnimation = true;

      let counter = 0;
      let lastRandomIndex = 0;
      this.timer = setInterval(() => {
        // Alle aktiven Klassen entfernen
        document
          .querySelectorAll(".animation-item")
          .forEach((el) => el.classList.remove("active"));

        // Ein zufälliges Element auswählen und die aktive Klasse hinzufügen
        const itemsWrapper = document.querySelector(
          ".animation-wrapper .animation-inner"
        );
        if (itemsWrapper !== null) {
          const items = itemsWrapper.querySelectorAll(".animation-item");
          let randomIndex = 0;
          do {
            randomIndex = Math.floor(Math.random() * items.length);
          } while (randomIndex == lastRandomIndex);
          lastRandomIndex = randomIndex;
          items[randomIndex].classList.add("active");

          // Timer und Counter aktualisieren
          counter += 300;
          if (counter >= 3000) {
            clearInterval(this.timer);
            this.timer = 0;
            items.forEach((el) => el.classList.remove("active"));
            itemsWrapper.classList.add("finished"); // das fünfte Element
            items[4].classList.add("active"); // das fünfte Element
            setTimeout(() => {
              items[4].classList.add("finish"); // das fünfte Element
            }, 1000);
            setTimeout(() => {
              const storeName =
                this.$router.currentRoute.value.params.storeName;
              this.$router.push(`/${storeName}/main`).then(() => {
                this.setAppNavigated(false);
              });
            }, 2500);
          }
        }
      }, 300);
    },
    calcInitialDiscount() {
      let voucher = null;
      do {
        voucher = this.randomReward();
      } while (voucher === null);
      let updatedProgress = {
        ...this.currentProgress,
        initialDiscount: voucher,
      };
      this.setCurrentProgress(updatedProgress);
    },
    randomReward() {
      let sum = 0;
      let totalProbability = 0;
      for (const item of this.currentStore.activeEvent.coupons) {
        totalProbability += item.probability;
      }
      const r = Math.random() * totalProbability;

      for (const item of this.currentStore.activeEvent.coupons) {
        sum += item.probability;
        if (r <= sum) {
          return item.value;
        }
      }
      return null; // Dieser Fall sollte nie eintreten, wenn die Wahrscheinlichkeiten insgesamt 1 ergeben
    },
    abortRaffle() {
      if (this.timer === 0) return;
      this.setAppNavigated(true);
      const itemsWrapper = document.querySelector(
        ".animation-wrapper .animation-inner"
      );
      if (itemsWrapper !== null) {
        const items = itemsWrapper.querySelectorAll(".animation-item");
        clearInterval(this.timer);
        items.forEach((el) => el.classList.remove("active"));
        itemsWrapper.classList.add("finished"); // das fünfte Element
        items[4].classList.add("active"); // das fünfte Element
        setTimeout(() => {
          items[4].classList.add("finish"); // das fünfte Element
        }, 1000);
        setTimeout(() => {
          const storeName = this.$router.currentRoute.value.params.storeName;
          this.$router.push(`/${storeName}/main`).then(() => {
            this.setAppNavigated(false);
          });
        }, 2500);
        this.timer = 0;
      }
    },
  },
});
