
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "HomeView",
  computed: {
    ...mapState(["errorCode"]),
    errorMessage() {
      switch (this.errorCode) {
        case "ERROR_API":
          return "Leider ist bei der Abfrage der Daten etwas schiefgelaufen - bitte versuche es später noch ein Mal.";
        default:
          return "Diese URL ist ungültig.";
      }
    },
  },
});
