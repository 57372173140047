
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import { determineCurrentStep } from "../router/index";

export default defineComponent({
  name: "StoreVoucherCodeActivation",
  computed: {
    ...mapState(["currentStore", "currentProgress"]),
    totalDiscount(): number {
      if (null === this.currentProgress.initialDiscount) return 0;
      let discount = this.currentProgress.initialDiscount;
      this.currentProgress.addedDiscounts.forEach((easterEggId: any) => {
        const matchingEasterEgg =
          this.currentStore.activeEvent.actionItems.find((easterEgg: any) => {
            return easterEgg.id === easterEggId;
          });
        discount += matchingEasterEgg.coupon.value;
      });
      return discount;
    },
  },
  data() {
    return {
      isDragging: false,
      initialDragPosition: 0,
      dragProgress: 0,
      SOME_THRESHOLD_VALUE: 230,
      inactivityTimer: 0,
      INACTIVITY_DELAY: 1500,
    };
  },
  methods: {
    ...mapActions([
      "setCurrentProgress",
      "setPreviousStep",
      "setCurrentStep",
      "stashTrackingInfo",
      "setAppNavigated",
    ]),
    prevStep() {
      this.setAppNavigated(true);
      this.setPreviousStep();
      this.setCurrentStep("shopping");
      this.stashTrackingInfo({});
      const storeName = this.$router.currentRoute.value.params.storeName;
      this.$router.push(`/${storeName}/shopping`).then(() => {
        this.setAppNavigated(true);
      });
    },
    nextStep() {
      //add tracking info
      this.setAppNavigated(true);
      this.setPreviousStep();
      this.setCurrentStep("codepreview");
      let newInfo = {
        finalTimestamp: Math.floor(new Date().getTime() / 1000),
      };
      this.stashTrackingInfo(newInfo);

      const storeName = this.$router.currentRoute.value.params.storeName;
      this.$router.push(`/${storeName}/codepreview`).then(() => {
        this.setAppNavigated(false);
      });
    },
    onDragStart(event: MouseEvent | TouchEvent) {
      this.isDragging = true;
      this.resetInactivityTimer();
      this.initialDragPosition =
        (event as MouseEvent).clientX ||
        (event as TouchEvent).touches[0].clientX;

      const arrow = this.$refs.activationArrow as HTMLElement;
      arrow.classList.add("dragging");
      // event.preventDefault();
    },

    onDragMove(event: MouseEvent | TouchEvent) {
      this.resetInactivityTimer();
      if (!this.isDragging) return;

      const currentPosition =
        (event as MouseEvent).clientX ||
        (event as TouchEvent).touches[0].clientX;
      let moveAmount =
        this.dragProgress + (currentPosition - this.initialDragPosition);

      const maxMove = 232;
      if (moveAmount > maxMove) moveAmount = maxMove;
      else if (moveAmount < 0) moveAmount = 0;

      const arrow = this.$refs.activationArrow as HTMLElement;
      arrow.style.left = `${moveAmount}px`;

      if (moveAmount >= this.SOME_THRESHOLD_VALUE) {
        this.saveCurrentDateTime();
        this.nextStep();
        this.isDragging = false;
        arrow.style.left = `0px`;
        arrow.classList.remove("dragging");
      }

      this.initialDragPosition = currentPosition;
      this.dragProgress = moveAmount;
      // event.preventDefault();
    },

    onDragEnd(event: MouseEvent | TouchEvent) {
      if (!this.isDragging) return;
      const arrow = this.$refs.activationArrow as HTMLElement;
      arrow.style.left = `0px`;
      arrow.classList.remove("dragging");
      this.isDragging = false;
      this.dragProgress = 0;
      event.preventDefault();
    },
    saveCurrentDateTime() {
      const currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() + 5);
      currentDate.setSeconds(currentDate.getSeconds() + 1);
      let expiryDateTime;
      if (typeof this?.currentProgress?.stepData?.expiryTime !== "undefined")
        expiryDateTime = this.currentProgress.stepData.expiryTime;
      else expiryDateTime = Math.floor(currentDate.getTime() / 1000);
      let updatedProgress = {
        ...this.currentProgress,
        stepData: {
          ...this.currentProgress.stepData,
          expiryTime: expiryDateTime,
        },
      };
      this.setCurrentProgress(updatedProgress);
    },
    resetInactivityTimer() {
      if (this.inactivityTimer) clearTimeout(this.inactivityTimer);
      this.inactivityTimer = setTimeout(
        this.animateDueToInactivity,
        this.INACTIVITY_DELAY
      );
    },
    animateDueToInactivity() {
      if (this.isDragging) return;
      const arrow = this.$refs.activationArrow as HTMLElement | null; // Note the added | null
      if (!arrow) return;
      const initialPosition = parseInt(arrow.style.left) || 0;
      const ANIMATION_DISTANCE = 20;
      arrow.style.left = `${initialPosition + ANIMATION_DISTANCE}px`;
      setTimeout(() => {
        arrow.style.left = `${initialPosition}px`;
        this.resetInactivityTimer();
      }, 300);
    },
  },
  mounted() {
    determineCurrentStep();
    window.addEventListener("mousemove", this.onDragMove);
    window.addEventListener("mouseup", this.onDragEnd);
    window.addEventListener("touchmove", this.onDragMove);
    window.addEventListener("touchend", this.onDragEnd);
    this.resetInactivityTimer();
  },

  beforeUnmount() {
    window.removeEventListener("mousemove", this.onDragMove);
    window.removeEventListener("mouseup", this.onDragEnd);
    window.removeEventListener("touchmove", this.onDragMove);
    window.removeEventListener("touchend", this.onDragEnd);
    if (this.inactivityTimer) clearTimeout(this.inactivityTimer);
  },
});
