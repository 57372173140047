
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import InlineSvg from "vue-inline-svg";
import { determineCurrentStep } from "../router/index";

export default defineComponent({
  name: "StoreVoucherFinisher",
  components: {
    InlineSvg,
  },
  computed: {
    ...mapState(["currentProgress"]),
    realFinish() {
      const expiryTime = this?.currentProgress?.stepData?.expiryTime;
      if (typeof expiryTime !== "undefined") {
        const now = Math.floor(Date.now() / 1000);
        return this.currentProgress.stepData.expiryTime <= now;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["setCurrentStep"]),
  },
  mounted() {
    determineCurrentStep();
    if (this.realFinish) this.setCurrentStep("expired");
  },
});
