
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";
import { determineCurrentStep } from "../router/index";

export default defineComponent({
  name: "StoreVoucherExpired",
  components: {
    InlineSvg,
  },
  mounted() {
    determineCurrentStep();
  },
});
