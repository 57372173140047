
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import { determineCurrentStep } from "../router/index";

export default defineComponent({
  name: "StoreMainVoucher",
  computed: {
    ...mapState(["currentStore", "currentProgress"]),
  },
  mounted() {
    determineCurrentStep();
  },
  methods: {
    ...mapActions([
      "setCurrentProgress",
      "setPreviousStep",
      "setCurrentStep",
      "stashTrackingInfo",
      "setAppNavigated",
    ]),
    nextStep() {
      this.setAppNavigated(true);
      this.setPreviousStep();
      this.setCurrentStep("dashboard");
      this.stashTrackingInfo({});
      const storeName = this.$router.currentRoute.value.params.storeName;
      this.$router.push(`/${storeName}/dashboard`).then(() => {
        this.setAppNavigated(false);
      });
    },
  },
});
