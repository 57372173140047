
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseOverlay",
  props: {
    page: {
      type: Object,
      require: true,
    },
    referrer: {
      type: String,
      require: false,
      default: "",
    },
  },
  data() {
    return {
      isComplete: false,
    };
  },
  methods: {
    close(): void {
      this.$emit("closeLightbox", {
        isComplete: this.isComplete,
      });
      document.body.scrollTo({ top: 1 });
      window.scrollTo({ top: 1 });
    },
  },
});
